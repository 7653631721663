import React, { useContext, useEffect } from 'react';
import { useAdtopiaLoadApi } from '../Hooks/useAdtopiaLoadApi';
import { AppDomainWithoutProtocol, AppDomain, AppLpDefaultUrl, AppApDefaultUrl, AppAdtopiaUPID, AppReferrerURL } from "../Constants/EnvConstants";
import { getQueryString } from "../Utility/QueryString";
import { UUID } from "./UUID";
import { useHistory } from "react-router-dom";
import { CheckRedirectUrl } from "../Utility/CheckRedirectUrl";
import RedirectContext from "../Contexts/RedirectContext";

const AdtopiaLoadRoot = ({ splitName }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const history = useHistory();
  const encryptVal = urlParams.get('value');
  const { getUUID } = UUID();
  const { adtopiaLoadResponse, adtopiaLoadingErr } = useAdtopiaLoadApi();
  const { redirectUrl } = useContext(RedirectContext);
  const { isLpUrlExist } = CheckRedirectUrl();
  const field = 'uuid';
  var redirectURL = "";
  var url = "";
  var queryString = getQueryString();

  useEffect(() => {
    (async () => {
      let uuidType = 'new';
      const getUUIDParam = await getUUID(
        splitName,
        uuidType
      );
      if(queryString == ""){
        url = AppDomain;
      }else{
        url = AppDomain + '?' + queryString;
      }
      const uuid = getUUIDParam;
      const getdtopiaApi = await adtopiaLoadResponse(
        url,
        AppDomainWithoutProtocol,
        window.navigator.userAgent,
        uuid,
        AppAdtopiaUPID,
        AppReferrerURL
      );
      if (adtopiaLoadingErr) {
        history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);
      }
      if (typeof getdtopiaApi !== "undefined") {
        const getdtopiaApiResponse = getdtopiaApi.data;
        if (getdtopiaApiResponse.page_type == 'advertorial') {
          if (getdtopiaApiResponse.lp_url) {
            redirectURL = getdtopiaApiResponse.lp_url;
          } else {
            redirectURL = await isLpUrlExist();
          }
          redirectUrl.redirectUrl = redirectURL;
          if (getdtopiaApiResponse.response === 200) {
            if (getdtopiaApiResponse.domain_name === AppDomainWithoutProtocol) {
              if (getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1) {
                history.push('/' + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param /*+ '&lp_url=' + redirectURL*/);
              } else {
                history.push('/' + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param + '&uuid=' + uuid /*+ '&lp_url=' + redirectURL*/);
              }
            } else {
              window.location.href = getdtopiaApiResponse.url + '&uuid=' + uuid /*+ '&lp_url=' + redirectURL*/;
            }
          } else if (getdtopiaApiResponse.response === 404) {
            if (getdtopiaApiResponse.url_param != "") {
              if (getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1) {
                history.push('/' + AppApDefaultUrl + '?' + getdtopiaApiResponse.url_param /*+ '&lp_url=' + redirectURL*/);
              } else {
                history.push('/' + AppApDefaultUrl + '?' + getdtopiaApiResponse.url_param + '&uuid=' + uuid /*+ '&lp_url=' + redirectURL*/);
              }
            } else {
              history.push('/' + AppApDefaultUrl + '?uuid=' + uuid /*+ '&lp_url=' + redirectURL*/);
            }
          } else {
            if (getdtopiaApiResponse.url_param != "") {
              if (getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1) {
                history.push('/' + AppApDefaultUrl + '?' + getdtopiaApiResponse.url_param /*+ '&lp_url=' + redirectURL*/);
              } else {
                history.push('/' + AppApDefaultUrl + '?' + getdtopiaApiResponse.url_param + '&uuid=' + uuid /*+ '&lp_url=' + redirectURL*/);
              }
            } else {
              history.push('/' + AppApDefaultUrl + '?uuid=' + uuid /*+ '&lp_url=' + redirectURL*/);
            }
          }
        }
        else if (getdtopiaApiResponse.page_type == 'landingpage') {
          if (getdtopiaApiResponse.response === 200) {
            if (getdtopiaApiResponse.domain_name === AppDomainWithoutProtocol) {
              if (getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1) {
                history.push('/' + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param);
              } else {
                history.push('/' + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param + '&uuid=' + uuid);
              }
            } else {
              window.location.href = getdtopiaApiResponse.url + '&uuid=' + uuid;
            }
          } else if (getdtopiaApiResponse.response === 404) {
            if (getdtopiaApiResponse.url_param != "") {
              if (getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1) {
                history.push('/' + AppLpDefaultUrl + '?' + getdtopiaApiResponse.url_param);
              } else {
                history.push('/' + AppLpDefaultUrl + '?' + getdtopiaApiResponse.url_param + '&uuid=' + uuid);
              }
            } else {
              history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);
            }
          } else {
            if (getdtopiaApiResponse.url_param != "") {
              if (getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1) {
                history.push('/' + AppLpDefaultUrl + '?' + getdtopiaApiResponse.url_param);
              } else {
                history.push('/' + AppLpDefaultUrl + '?' + getdtopiaApiResponse.url_param + '&uuid=' + uuid);
              }
            } else {
              history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);
            }
          }
        } else {
          history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);
        }
      } else {
        redirectURL = AppLpDefaultUrl;

        history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);
      }
      localStorage.setItem('redirectURL', redirectURL);
    })()
  }, [])
  return (
    <></>
  )
}

export default AdtopiaLoadRoot;