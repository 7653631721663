export const getQueryString = () => {
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  if (query_string_arr && query_string_arr[1]) {
    return query_string_arr[1];
  } else {
    return '';
  }
}


